<template>
  <div class="social-media-content">
    <div class="social-media-content-info">
      <div class="social-media-left-part">
        <h1>Blast special updates & exclusive offers to your customers</h1>

        <div class="social-media-logos">
          <img src="img/instagram-logo.png" alt="" />
          <img src="img/tiktok-logo.png" alt="" />
          <img src="img/youtube-logo.png" alt="" />
          <img src="img/spotify-logo.svg" alt="" />
        </div>

        <li class="first-list-item">
          Share special updates and exclusive offers to your customers.
        </li>
        <li class="first-list-item">
          Link content from YouTube, TikTok, & other platforms.
        </li>
        <li class="first-list-item">
          Engage with customers to drive long-term retention.
        </li>
      </div>

      <div class="social-media-right-part">
        <img
          v-motion
          src="img/blast-special-updates.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.social-media-content {
  width: 100%;
  background-color: #f3f7f8;
}

.social-media-content-info {
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-left: 2px solid white;
}

.social-media-left-part h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-left: 25px;
  padding-right: 20px;
  color: #25a4e1;
}

.social-media-left-part h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-left: -2px;
  padding-left: 1.45rem;
  border-left: 2px solid #25a4e1;
}

.social-media-logos {
  width: 50%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1.45rem;
}

.social-media-logos img {
  width: 70%;
}

.social-media-left-part li {
  width: 100%;
  align-items: center;
  font-size: 1rem;
  top: -10rem;
  line-height: 2;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-left: 1.45rem;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 20%;
  background-size: 1rem;
}

.first-list-item {
  line-height: 1.5;
  font-weight: 500;
  opacity: 0.6;
}

.social-media-right-part {
  width: 90%;
  margin: auto;
}

.social-media-right-part img {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .social-media-content-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .social-media-content-info {
    width: 40rem;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .social-media-content-info {
    width: 90%;
    border-left: none;
  }

  .social-media-left-part h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .social-media-left-part h1 {
    font-size: 1.5rem;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-logos {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-right-part {
    width: 100%;
  }

  .social-media-left-part li {
    margin-left: 0;
  }
}
</style>
