<template>
  <div class="social-media-content">
    <div class="social-media-content-info">
      <div class="social-media-left-part">
        <h1>Automated reminders for upcoming events</h1>
        <p>
          Schedule notifications with customized text to remind customers about
          your upcoming event.
        </p>

        <li class="first-list-item">
          <p>Automated payment confirmation messages</p>
        </li>
        <li class="first-list-item">
          <p>
            Real time updates when payments are confirmed. No more confusing
            reconciliations and payment screenshots.
          </p>
        </li>
        <li class="first-list-item">
          <p>
            Schedule email about important information, guides, and
            announcements about the event
          </p>
        </li>
      </div>

      <div class="social-media-right-part">
        <img
          v-motion
          src="img/automated-reminders.png"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.social-media-content {
  width: 100%;
  background-color: #f3f7f8;
}

.social-media-content-info {
  width: 71rem;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  border-left: 2px solid white;
}

.social-media-left-part h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-left: 30px;
  padding-right: 20px;
  color: #25a4e1;
}

.social-media-left-part h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-left: -2px;
  padding-left: 1.45rem;
  border-left: 2px solid #25a4e1;
}

.social-media-left-part > p {
  margin-left: 1.45rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2;
  opacity: 0.6;
  margin-bottom: 3rem;
}

.social-media-left-part li {
  width: 100%;
  align-items: center;
  font-size: 1rem;
  top: -10rem;
  line-height: 2;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-left: 1.45rem;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 20%;
  background-size: 1rem;
}

.social-media-left-part li p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.social-media-right-part {
  width: 70%;
  margin: auto;
}

.social-media-right-part img {
  width: 90%;
  margin: auto;
}

@media only screen and (max-width: 1400px) {
  .social-media-content-info {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .social-media-content-info {
    width: 40rem;
    grid-template-columns: 1fr;
  }

  .social-media-right-part img {
    width: 63%;
    margin: auto;
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .social-media-content-info {
    width: 90%;
    border-left: none;
  }

  .social-media-left-part h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .social-media-left-part h1 {
    font-size: 1.5rem;
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-left-part p {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-logos {
    margin-left: 0;
    padding-left: 0;
  }

  .social-media-right-part {
    width: 100%;
  }

  .social-media-right-part img {
    width: 90%;
  }

  .social-media-left-part li {
    margin-left: 0;
  }
}
</style>
