<template>
  <div>
    <header>
      <HeaderNavHomepage />
      <main>
        <div class="main-page">
          <div class="left-part">
            <h1>Automated Notifications for every situation</h1>
            <p class="left-para">
              Payment confirmations, follow-up reminders, and special
              announcements. All automated.
            </p>
            <div class="cta-btn">
              <a :href="SIGN_UP_URL" class="signup"> Sign-up for Free </a>
              <!-- <a
                href="https://dashboard.meetbit.io/meeting/HelixPay"
                class="schedule"
              >
                Schedule Demo
              </a> -->
            </div>
          </div>

          <img src="/img/blur-circle.svg" class="blur1" alt="" />
          <img src="/img/blur-circle.svg" class="blur2" alt="" />
          <img src="/img/blur-circle.svg" class="blur3" alt="" />
        </div>
      </main>
    </header>

    <div class="hero">
      <MissedPayments />
      <RevenueGrowth />
      <BlastSpecialUpdates />
      <MainFooter />
    </div>
  </div>
</template>

<script setup>
import BlastSpecialUpdates from '@/components/BlastSpecialUpdates.vue';
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainFooter from '@/components/MainFooter.vue';
import MissedPayments from '@/components/MissedPayments.vue';
import RevenueGrowth from '@/components/RevenueGrowth.vue';
import { SIGN_UP_URL } from '@/constants';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

useHead(
  buildPageMeta({
    title:
      'Automated Notifications | Ecommerce Technology for Creators & Communities',
    description:
      'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
    url: 'https://www.helixpay.ph/automatednotif',
    image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
  })
);
</script>

<style scoped>
main {
  width: 100%;
  height: inherit;
  position: relative;
  overflow: hidden !important;
  background-color: black;
}

.main-page {
  display: grid;
  grid-template-columns: 1fr;
  width: 71rem;
  margin: auto;
  padding-top: 7rem;
  padding-bottom: 7rem;
  align-items: center;
}

.left-part {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  align-items: left;
  justify-content: left;
  z-index: 9;
}

.left-part h1 {
  width: 100%;
  font-size: 2.6rem;
  font-weight: 700;
  color: white;
  line-height: 1.3;
  margin: 0;
  margin-bottom: 2rem;
  text-align: left;
}

.left-para {
  color: white;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-btn {
  width: 100%;
  display: flex;
}

.cta-btn a {
  text-align: center;
  margin-top: 10px;
  padding: 1rem;
}

.signup {
  width: 100%;
  background-color: #25a4e1;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.schedule {
  width: 100%;
  background-color: #9affe1;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  padding: 20px 30px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
}

.signup:hover {
  background-color: #2094ca;
}

.schedule:hover {
  background-color: #74e2c1;
}

.hero {
  background-color: #f3f7f8;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN CIRCLE */

.blur1 {
  width: 45%;
  right: -15%;
  bottom: -30%;
  z-index: 8;
  position: absolute;
  pointer-events: none;
  animation: move 200s ease-out infinite;
}

@keyframes move {
  0% {
    width: 45%;
    right: -20%;
  }
  100% {
    width: 50%;
    right: 100%;
    bottom: 40%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 2 */

.blur2 {
  opacity: 0.5;
  width: 15%;
  left: 1%;
  bottom: -30%;
  z-index: 7;
  position: absolute;
  pointer-events: none;
  animation: right 140s ease-out infinite;
}

@keyframes right {
  0% {
    width: 15%;
    left: -15%;
    bottom: -10%;
  }
  100% {
    width: 25%;
    left: 100%;
    bottom: 20%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 3 */

.blur3 {
  opacity: 0.5;
  width: 5%;
  left: 1%;
  top: -10%;
  z-index: 6;
  position: absolute;
  pointer-events: none;
  animation: lower 150s ease-out infinite;
}

@keyframes lower {
  0% {
    width: 5%;
    left: -15%;
    top: -10%;
  }
  100% {
    width: 10%;
    left: 100%;
    top: 50%;
  }
}

@media only screen and (max-width: 1400px) {
  .main-page {
    width: 60rem;
  }

  .second-section-info {
    width: 40rem;
  }
}

@media only screen and (max-width: 1050px) {
  .main-page {
    height: inherit;
    width: 40rem;
    grid-template-columns: 1fr;
    gap: 3rem;
    position: inherit;
  }

  .left-part {
    width: 100%;
  }

  .left-part h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .left-para {
    text-align: center;
  }

  .second-section-info {
    width: 30rem;
  }

  .second-section-info h2 {
    font-size: 1.5rem;
  }

  .second-section-info h3 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 650px) {
  .cta-btn {
    width: 60%;
    display: grid;
    margin: auto;
    margin-top: 2rem;
  }

  .main-page {
    width: 100%;
    gap: 0;
    padding: 4rem 0;
  }

  .left-part h1 {
    width: 85%;
    margin: auto;
    font-size: 1.5rem;
  }

  .left-para {
    width: 85%;
    padding-top: 2rem;
    font-size: 1rem;
    margin: auto;
  }

  .second-section-info {
    width: 20rem;
  }

  .second-section-info h2 {
    font-size: 1.5rem;
  }
}
</style>
