<template>
  <div class="revenue-main">
    <div class="revenue-growth">
      <div class="revenue-growth-right-part forMobile">
        <img
          v-motion
          src="/img/engagement-tools.png"
          class="welcome-email forMobile"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <h1>
        Engagement tools and notifications for better customer experience with
        customized settings
      </h1>

      <div class="revenue-growth-info">
        <div class="revenue-growth-left-part">
          <li class="first-list-item">
            <p>
              Notification messages via email and SMS sent automatically with
              custom design style for your brand.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Send customized content to customers with updates on upcoming
              events or other info to increase conversion rate.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Customer Profile Page that allows customers to edit their
              information, view their tickets, and view their past transactions.
            </p>
          </li>
        </div>

        <div class="revenue-growth-right-part forWeb">
          <img
            v-motion
            src="/img/engagement-tools.png"
            class="welcome-email forWeb"
            alt=""
            :initial="{
              opacity: 0,
              y: 100,
            }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 100,
                stiffness: 200,
                damping: 90,
                mass: 3,
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none !important;
}

.revenue-main {
  width: 100%;
  background-color: #f3f7f8;
}

.revenue-growth {
  width: 71rem;
  margin: auto;
  padding: 3rem 0;
  border-left: 2px solid white;
}

.revenue-growth-info p {
  line-height: 2;
  font-weight: 500;
  opacity: 0.6;
}

.revenue-growth h4 {
  font-weight: 700;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-left: 30px;
  padding-right: 20px;
  color: #25a4e1;
}

.revenue-growth h1 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  margin-left: -2px;
  padding-left: 30px;
  padding-right: 20px;
  border-left: 2px solid #25a4e1;
}

.revenue-growth-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  padding-bottom: 2rem;
  width: 95%;
  align-items: center;
}

.revenue-growth-left-part li {
  font-size: 1rem;
  line-height: 2;
  top: -10rem;
  margin-top: 1rem;
  margin-bottom: 20px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 15%;
  background-size: 1rem;
  text-indent: -0.1em;
}

.revenue-growth-left-part {
  width: 100%;
}

.revenue-growth-para {
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
  opacity: 0.5;
}

.revenue-growth-right-part {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.revenue-growth-right-part img {
  width: 80%;
  margin: auto;
  display: block;
}

.welcome-email {
  width: 100%;
  height: 100%;
  border: 10px solid black;
  border-radius: 20px;
  box-shadow: 10px 10px #25a4e1;
}

@media only screen and (max-width: 1400px) {
  .revenue-growth {
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block !important;
    margin-bottom: 2rem;
  }

  .forWeb,
  .welcome-email {
    display: none;
  }

  .revenue-growth {
    width: 40rem;
  }

  .revenue-growth-info {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .revenue-growth {
    width: 90%;
    border-left: 0;
  }

  .revenue-growth-info {
    width: 100%;
  }

  .revenue-growth h4 {
    font-size: 1rem;
    padding-left: 0;
    margin-left: 0;
  }

  .revenue-growth h1 {
    font-size: 1.5rem;
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .revenue-growth-left-part li {
    background-position: 0% 5%;
  }

  .revenue-growth-right-part img {
    width: 100%;
  }
}
</style>
